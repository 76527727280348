import React, { useEffect, useState } from "react";
import { Container, Col, Row, Nav, Navbar, Button, Dropdown, NavDropdown } from "react-bootstrap";
import { Helmet } from "react-helmet"
import NavLink from "./components/nav-link";
import {Link} from "gatsby";
import "./style/index.scss";
import ApolloManager from "./components/apollo";
import {initI18} from "./logic/i18n";
import { getCurrentUserQuery, getCurrentUserResult, logoutMutation } from "./logic/user";
import { useMutation, useQuery } from "@apollo/client";
import { getOperationName } from "@apollo/client/utilities";
import galleryData from "./images/gallery/gallery.json";
import HomeImage from "./images/mobile-bg.jpg";

function NavItem(props) {
  const LinkComponent = props.link ? Link : NavLink;
  return (<div className="menu-item">
    <LinkComponent to={props.to} onClick={props.onClick}>
      <Container fluid>
        <Row className="align-items-center">
          <Col className="snp-user-icon" xs={2}>
            <i className={props.icon}/>
          </Col>
          <Col xs={"auto"}>
            {props.children}
          </Col>
        </Row>
      </Container>
    </LinkComponent>
  </div>);
}

function scrollGallery() {
  return document.getElementById("gallery")?.scrollTo({
    top: 0
  })
}

export default function Layout(props) {
  const [showPortfolio, setShowPortfolio] = useState(false);
  useEffect(() => {
    initI18("en");
  }, []);
  return (<ApolloManager>
    <div className="vh-100 fh">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"SNPhotograhy"}</title>
        <link rel="canonical" href="https://snphotography.com.au" />
        <link rel="stylesheet" href="/css/font-awesome/css/fontawesome.min.css" media="all"/>
        <link rel="stylesheet" href="/css/font-awesome/css/all.min.css" media="all"/>
      </Helmet>
      <Navbar expand="lg" style={{backgroundImage: `url(${HomeImage})`}}>
        <Navbar.Brand href="/"><img src="/logos/Sharon-Newman_logo_RGB_gold.png" className="img-fluid" style={{maxHeight: 60}} /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavItem to="/" icon="fal fa-home">{"Home"}</NavItem>
            <NavDropdown title={(<div style={{display: "inline-block", width: "100%", marginRight: -15}}>
              
              <Container fluid>
                <Row className="align-items-center">
                  <Col className="snp-user-icon" xs={2}>
                    <i className="fal fa-images"/>
                  </Col>
                  <Col xs={"auto"}>
                    {"Portfolio"}
                  </Col>
                </Row>
              </Container>
            </div>)}>
              {Object.keys(galleryData).map((code) => {
                  const gallery = galleryData[code];
                  return (<NavDropdown.Item>
                    <NavItem onClick={scrollGallery} link to={`/portfolio?code=${code}`} icon={gallery.icon}>
                      {gallery.displayName}
                    </NavItem>
                  </NavDropdown.Item>
                  );
                })}
            </NavDropdown>
            <NavItem to="/portal" icon="fal fa-user-cog">{"Portal"}</NavItem>
            <NavItem to="/about" icon="fal fa-cloud-meatball">{"About"}</NavItem>
            <NavItem to="/contact" icon="fal fa-id-card">{"Contact"}</NavItem>
            <NavLink>
              <UserProfile iconCol={2} />
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="snp-navbar-container snp-navbar fh">
        <Container fluid className="no-gutters fh-element fh">
          <Row className="align-items-center fh-element">
            <Col xs="auto">
              <div className="snp-navbar-inner">
                <Container fluid className="no-gutters">
                  <MenuItem icon="fal fa-home" to="/">
                    {"Home"}
                  </MenuItem>
                  <MenuItem onClick={() => setShowPortfolio(!showPortfolio)} icon="fal fa-images" >
                    {"Portfolio"}
                  </MenuItem>
                  {showPortfolio && (<MenuItem noLabel>
                    {Object.keys(galleryData).map((code) => {
                        const gallery = galleryData[code];
                        return (<Container fluid>
                          <Row className="align-items-center">
                            <Col xs={"auto"}>
                              <i className={gallery.icon}/>
                            </Col>
                            <Col xs={"auto"}>
                              <Link onClick={scrollGallery} to={`/portfolio?code=${code}`}>{gallery.displayName}</Link>
                            </Col>
                          </Row>
                        </Container>);
                      })}
                  </MenuItem>)}
                  <MenuItem icon="fal fa-user-cog" to="/portal">
                    {"Portal"}
                  </MenuItem>
                  <MenuItem icon="fal fa-cloud-meatball" to="/about">
                    {"About"}
                  </MenuItem>
                  <MenuItem icon="fal fa-id-card" to="/contact">
                    {"Contact"}
                  </MenuItem>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="snp-user-profile">
        <UserProfile iconCol="auto" />
      </div>
      {props.children}
    </div>
  </ApolloManager>);
}

function UserProfile(props) {
  const currentUserQuery = useQuery(getCurrentUserQuery);
  const [logout] = useMutation(logoutMutation);
  async function handleLogout() {
    await logout({
      refetchQueries: [getOperationName(getCurrentUserQuery)],
    });
    window.location = "/";
  }
  if(currentUserQuery.loading) {
    return (<React.Fragment/>);
  }

  const userResult = getCurrentUserResult(currentUserQuery);
  const user = userResult?.user;
  // console.log("user", user);
  return (<Container fluid>
    <Row className={"align-items-center"}>
      <Col className="snp-user-icon" xs={props.iconCol}>
        <i className="fal fa-users" />
      </Col>
      {!user ? (<Col className="snp-user-login-link">
        <Link to="/login">{"Login"}</Link>
      </Col>) : (<Col className="snp-user-login-link">
        <Dropdown>
          <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
            {`${user.firstName} ${user.lastName}`}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item eventKey="1" onClick={handleLogout}>
              {"Logout"}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>)}
      
    </Row>
  </Container>);
}

function MenuItem(props) {
  return (<Row onClick={props.onClick} className="snp-navbar-menu-item align-items-center">
    
    {props.icon && (<Col xs={"auto"}>
      <div className="snp-navbar-menu-item-inner">
        <Link to={props.to}>
          <i className={props.icon}/>
        </Link>
      </div>
    </Col>)}
    <Col className="">
      {(() => {
        if(props.href) {
          return (<a href={props.href} target={props.target}>
            <span className={"snp-navbar-menu-item-label"}>
              {props.children}
            </span>
          </a>) 
        } else if (props.to) {
          return (<Link to={props.to}>
            <span className={"snp-navbar-menu-item-label"}>
              {props.children}
            </span>
          </Link>);
        }
        return (<span className={!props.noLabel ? "snp-navbar-menu-item-label": "snp-navbar-menu-item-alt"}>
          {props.children}
        </span>)
      })()}
      
    </Col>
  </Row>);
}


// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));
